<template>
  <v-container fluid class="pa-1">
    <error-alert />
    <navbar @drawer='drawer' title="سبدخرید" route = '/'/>
    <v-main class="pt-15 d-flex flex-column justify-center" style="padding-bottom: 90px">
      <div v-if="products.length > 0">
      <div v-for ="product in products" :key="product.id">
      <card :product= 'product' class="mx-3"/>
      </div>
      </div>
      <div v-else class="mt-10 d-flex flex-column align-center justify-center">
        <div>
        <img src="@/assets/icon/carts.svg" width="150">
        </div>
        <span v-if="_Token" class="font">سبد خرید شما خالی است</span>
        <div class="text-center">
        <span v-if="!_Token" class="medium-font">برای مشاهده سبدخرید ابتدا  <span class="info--text medium-font" @click="changePage('login')"> وارد حساب کاربری</span> خود شوید</span>
        </div>
      </div>
      <loading-page v-if="loading" />
    </v-main>
    <div class="btn-container">
        <bfooter title="انتخاب آدرس" v-if="products.length > 0"/>
        <btn title="بازگشت و خرید" v-else/>
    </div>
  </v-container>  
</template>
<style lang="scss" scoped>
.btn-container{
  padding-top: 100px;
}
</style>
<script>
import navbar from '@/components/navbar/appBar'
import card from './components/cards/basketCard'
import bfooter from './components/buttons/basketFooterBtn'
import btn from './components/buttons/setTimeBtn'
import { viewbasket_products } from './models/Basket'
import { mapState } from 'vuex'
import ErrorAlert from '@/components/alerts/error.alert.vue'
import LoadingPage from '@/components/tools/loadingPage.vue'
import router from '@/router'
import store from '@/store'
export default {
  components: {
    navbar,
    card,
    bfooter,
    ErrorAlert,
    btn,
    LoadingPage
  },
  created () {
    scrollTo(0,0)
    store.dispatch('clear_basket')
    viewbasket_products()
    store.dispatch('productDetail')
    this._Token = localStorage.getItem('access_token') 
  },
  computed: {
    ...mapState({
      products: state => state.basket.basket,
      loading: state => state.basket.load_page_basket
    })
  },
  data: () => ({
    changeDrawer: false,
    _Token: ''
  }),
  methods: {
    drawer (drawer) {
      this.changeDrawer = drawer
    },
    shoping () {
      router.push('/')
    },
    changePage (page) {
      this.basket = true
      router.push(`/${page}`)
    }
  }
}
</script>