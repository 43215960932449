<template>
  <v-card class="mt-2 card flat-card" color="surface">
    <info v-if="product.error" :total="total" :error="product" />
    <v-card-text>
      <v-row>
        <v-col cols="4" class="pa-1">
          <v-avatar class="profile" size="80" tile>
            <v-img
              @click="productDetail(product.id)"
              :src="product.image"
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="8" class="pa-1">
          <div class="title-container onSurface--text">
            <div>
              <span
                class="onSurface--text"
                v-text="product.title.slice(0, 50)"
              ></span>
              <span v-if="product.title > product.title.slice(0, 50)">...</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="4" class="pa-2">
          <span class="medium-font onSurface--text">واحد</span
          ><span class="smallFont mr-1">({{ product.unit_title }})</span>
        </v-col>
        <v-col cols="8" class="pa-2">
          <v-select
            :items="items"
            v-model="orderNew"
            @change="addBasket"
            v-if="product.Is_Bulk == 1"
            :placeholder="Order"
          ></v-select>
          <btn
            v-if="!product.Is_Bulk || product.Is_Bulk == 0"
            :product="product"
            @count="count"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-start align-center">
        <v-col cols="4" class="pa-2">
          <span class="medium-font onSurface--text">قیمت واحد</span>
        </v-col>
        <v-col cols="8" class="pa-2 d-flex">
          <div>
            <span
              class="error--text medium-font text-decoration-line-through ml-2"
              v-if="product.price != product.price_gross"
              v-text="product.price_gross_format"
            ></span>
          </div>
          <div>
            <span
              class="medium-font onSurface--text"
              v-text="product.price_formate"
            ></span>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="4" class="pa-2">
          <span class="medium-font onSurface--text">قیمت کل</span>
        </v-col>
        <v-col cols="6" class="pa-2">
          <span class="medium-font onSurface--text" v-text="totalPrice"></span>
        </v-col>
        <v-col class="pa-0 d-flex justify-end">
          <div
            v-if="_count != product.min_order || product.Is_Bulk == 1"
            @click="removeProduct"
          >
            <circleBtn icon="mdi-trash-can-outline" color="primaryActions" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <!-- <notice v-if="product.error" :error='product.error.message'/>
      <v-spacer></v-spacer>
      <div v-if="product.error">
      <v-icon @click="update()" v-if="product.error.action == 'update'" class="primary--text">mdi-refresh</v-icon>
      </div> -->
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.v-list {
  padding: 0 !important;
}
.v-input {
  font-size: 14px !important;
}
.v-list-item__content {
  padding: 0 !important;
}
.v-list-item__title {
  font-size: 14px !important;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
  border-radius: initial;
}
</style>
<script>
import btn from "../buttons/plusNegativeBtn";
import circleBtn from "@/components/buttons/circleFloatBtn";
import { addTObasket, removeTObasket } from "../../models/Basket";
import { changeTypePrice, changeTypePrice_format } from "@/models/changeType";
import info from "@/components/alerts/info.alert";
import router from "@/router";
export default {
  props: ["product"],
  data: () => ({
    items: [],
    Order: "",
    orderNew: "",
    totalPrice: "",
    total: 0,
    _count: 0,
  }),
  components: {
    btn,
    circleBtn,
    // notice,
    info,
  },
  created() {
    let order =
      parseInt(this.$props.product.min_order) *
      parseFloat(this.$props.product.Packing_Weight);
    const min_order_ =
      parseInt(this.$props.product.min_order) *
      parseFloat(this.$props.product.Packing_Weight);
    this.total =
      parseInt(this.$props.product.price) * this.$props.product.count;
    const countOrder = parseFloat(this.$props.product.count);
    this._count = parseInt(this.$props.product.count);
    this.totalPrice = changeTypePrice_format({
      price: parseInt(this.$props.product.price) * countOrder,
      Tax_price: 0,
    });
    if (this.$props.product.Is_Bulk == 1) {
      this.Order = countOrder.toFixed(1) + " کیلوگرم ";
      let lot_size = parseInt(this.$props.product.min_order);
      const Max_Order = Math.min(
        this.$props.product.onhand,
        this.$props.product.max_order
      );
      let min_order_while = parseInt(this.$props.product.min_order);
      while (min_order_while <= Max_Order) {
        const select = order.toFixed(1) + " کیلوگرم ";
        this.items.push(select);
        lot_size = lot_size + parseInt(this.$props.product.lot_size);
        min_order_while =
          min_order_while + parseInt(this.$props.product.lot_size);
        order = parseFloat(this.$props.product.Packing_Weight) * lot_size;
      }
    }
  },
  methods: {
    productDetail(productID) {
      router.push(`/product_detail/${productID}`);
    },
    addBasket() {
      const total = this.orderNew.split(" ")[0];
      this.totalPrice = changeTypePrice_format({
        price: parseInt(this.$props.product.price) * total,
        Tax_price: 0,
      });
      const count =
        parseFloat(this.orderNew.split(" ")[0]) /
        parseFloat(this.$props.product.Packing_Weight);
      if (this.total > parseInt(this.$props.product.price) * total) {
        addTObasket({
          product: {
            product_id: this.$props.product.id,
            count: count,
            source: "basket",
            org_id: this.$props.product.org_id,
            weight: this.$props.product.Packing_Weight,
          },
          price: this.total - parseInt(this.$props.product.price) * total,
          type: "-",
        });
        this.total = parseInt(this.$props.product.price) * total;
      } else if (this.total < parseInt(this.$props.product.price) * total) {
        addTObasket({
          product: {
            product_id: this.$props.product.id,
            count: count,
            source: "basket",
            org_id: this.$props.product.org_id,
            weight: this.$props.product.Packing_Weight,
          },
          price: parseInt(this.$props.product.price) * total - this.total,
          type: "+",
        });
        this.total = parseInt(this.$props.product.price) * total;
      }
    },
    count(count) {
      this._count = count;
      this.totalPrice = changeTypePrice_format({
        price: parseInt(this.$props.product.price) * count,
        Tax_price: 0,
      });
      this.total = parseInt(this.$props.product.price) * count;
    },
    removeProduct() {
      removeTObasket({
        product_id: this.$props.product.id,
        org_id: this.$props.product.org_id,
        price: this.total,
      });
    },
  },
};
</script>